* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.Dashboard {
  text-align: center;
  background: url(../images/background.jpg);
  font-family: "Roboto", sans-serif;
}

.Dashboard-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .Dashboard-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Dashboard-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Dashboard-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.personalInfo {
  margin: 0.5rem;
  border: 0px solid black;
  border-radius: 25px;
  background-color: rgb(3, 22, 35);
  text-align: center;
  width: 21%;
  height: 500px;
  color: aliceblue;
  float: left;
  position: fixed;
  font-size: 0.01rem;
  overflow: scroll;
}
.personalInfo::-webkit-scrollbar {
  display: none;
}

.achievements {
  margin: 0.5rem;
  border: 0px solid black;
  border-radius: 25px;
  background-color: rgb(3, 22, 35);
  text-align: center;
  width: 75%;
  background-size: cover;
  color: aliceblue;
  float: right;
  max-height: max-content;
  overflow: auto;
}
.Main {
  display: inline-block;
  width: 100%;
  /* background-color: black; */
  margin-top: 65px;
  background-size: cover;
  padding: 5px;
  overflow: hidden;
}
.Navbar {
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
}
.Info {
  margin-top: 10px;
}
.personContainer {
  border-radius: 100%;
  /* width: 150px; */
}
.person {
  margin-top: 1rem;
  border-radius: 100%;
  height: 120px;
  width: 120px;
  border: 0.25rem solid black;
}
.card {
  background-color: rgb(7, 7, 7);
  width: 40vw;
  height: 55vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
}
.cardText {
  color: white;
}
.cardTitle {
  color: black;
}
.cardImage {
  /* padding-top: 20px; */
  width: 300px;
}
.list-group-flush {
  color: black;
}

.DialogBox {
  min-width: 500px;
  height: 100vh;
  padding: 2vmax;
}
.PostHeader {
  background-color: #eeeeee;
}
.btn.btn-primary {
  background-color: darkslateblue;
}
.PersonalInformation {
  margin-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1.5rem;
}
.followingfollowers {
  display: flex;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-weight: bold;
}
.FollowersButton {
  flex: 1;
  display: inline-block;
  background: radial-gradient(rgba(99, 157, 223, 0.8), rgba(6, 48, 70, 0.801));
  border-radius: 25%;
  width: 100px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  color: white;
}

.FollowersButton:hover {
  margin-left: 0.5rem;
  background: radial-gradient(
    rgba(201, 110, 232, 0.8),
    rgba(47, 16, 62, 0.801)
  );
}
.Posts1 {
  background: radial-gradient(rgba(99, 157, 223, 0.8), rgba(6, 48, 70, 0.801));
  border-radius: 25%;
  width: 100px;
  height: 20px;
  margin-left: 31%;
}
.emailad {
  margin-bottom: 20px;
}
.logoutButton {
  margin-left: 10px;
  background: radial-gradient(
    rgba(99, 213, 223, 0.8),
    rgba(40, 168, 203, 0.801)
  );
  border-radius: 20%;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
}
.logoutButton:hover {
  color: black;
  margin-left: 12px;
  background: radial-gradient(
    rgba(104, 207, 230, 0.8),
    rgba(54, 208, 239, 0.801)
  );
}
.deleteMyProfile {
  margin-top: 20px;
  margin-left: 2px;
  margin-bottom: 20px;
  background: radial-gradient(
    rgba(202, 219, 218, 0.8),
    rgba(117, 134, 138, 0.801)
  );
  border-radius: 50%;
  color: black;
  font-weight: bold;
}
.deleteMyProfile:hover {
  color: maroon;
}
/* .buttonss{
display: block;
} */
/* .cpButton{

background: radial-gradient(rgba(99, 213, 223, 0.8), rgba(40, 168, 203, 0.801));
border-radius: 40%;
color: black;
font-weight: bold;
margin-top: 20px;
}
.cpButton:hover{
color: black;
margin-left: 12px;
background: radial-gradient(rgba(104, 207, 230, 0.8), rgba(54, 208, 239, 0.801));
} */

.posts {
  margin-bottom: 50px;
}

/* #responsiveness */

@media only screen and (max-width: 768px) {
  .personalInfo {
    display: none;
  }
  .achievements {
    margin-left: 10px;
    width: 96%;
  }
}
