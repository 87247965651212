.desc{
    color: azure;
}

.postbox{
    margin-left:10%;
    margin-right:5%;
    background-color:#EEEEEE;
    width: 80%;
    border-radius: 15px;
}


.DialogBox {
    
    min-width: 500px;
    height: 100vh;
    padding: 20%;
}

.commentForm {
    display: flex;
    margin: 1vmax;
  }
.commentForm > input {
    width: 100%;
    padding: 1vmax 2vmax;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.288);
    border-radius: 30px 0 0 30px;
    font: 100 1vmax "Roboto", sans-serif;
}
.commentForm > button {
    border-radius: 0%;
}
.PostHeader{
    padding-top: 10px;
    border-radius: 15px;
    height: 10%;
    
}
.postPicIcon{
    display: inline-block;
}
.pic{
    height: 4vmax;

                        width: 4vmax;
}

.postUName{
margin-left: 2%;
    display: inline-block;
    /* margin-bottom: 20px; */
    color: black;
    
}
.postUName:hover { text-decoration: none; }
.postUName:active { text-decoration: none; }
.postUName:link { text-decoration: none; }
.postUName:visited { text-decoration: none; }

.PUN{
    font-weight: bold;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.postThreeDotButton{
    display: inline-block;
    margin-left: 50%;
    background: radial-gradient(rgba(48, 96, 151, 0.637), rgba(23, 100, 123, 0.801));
    
}
.postImage{
    width:90%;
    height: 50%;

    border-radius:25px;
    border: 5px solid black;
 
}
.desc{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: bold;
    color: black;
    height: 80%;
}
.PostDetails{
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
}
.category{
    font-size: 150%;
    font-weight: bolder;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: black;
}
.postFooter{
    padding-bottom: 5%;
       padding-right: 2%;
       padding-left: 2%;
    
    
}
.likeCount{
    margin-left: 2%;
    background: radial-gradient(rgba(48, 96, 151, 0.637), rgba(23, 100, 123, 0.801));
}
.lc{
    margin-bottom: 2px;
}
.likeButton{

    background: radial-gradient(rgba(48, 96, 151, 0.637), rgba(23, 100, 123, 0.801));
}
.commentButton{
margin-left: 2%;
background: radial-gradient(rgba(48, 96, 151, 0.637), rgba(23, 100, 123, 0.801));
}
.deleteButton{
margin-left: 60%;
 background: radial-gradient(rgba(48, 96, 151, 0.637), rgba(23, 100, 123, 0.801));
}


@media only screen and (max-width: 1024px){
    .postFooter{
       padding-bottom: 5%;
       padding-right: 5%;
       padding-left: 5%;
    }
    .deleteButton{
        margin-left: 50%;
    }
    .postThreeDotButton{
        margin-left: 45%;

    }
    
}

@media only screen and (max-width: 768px){
    .postbox{
        margin-left:10%;
        margin-right:5%;

    }
    .postThreeDotButton{
        margin-left: 40%;
    }
    .deleteButton{
        margin-left: 35%;
    }
    .postThreeDotButton.btn.btn-primary{
        width: 10%;
    }
    

    

}
@media only screen and (max-width: 426px){
    
    .postThreeDotButton{
        margin-left: 10%;
        
    }
    .deleteButton{
        margin-left: 20%;
    }
    .likeCount.btn.btn-primary{
        width: 25%;

    }
    .btn.btn-primary{
        width: 15%;
    }
    .postThreeDotButton.btn.btn-primary{
        width: 13%;
    }
    

    

}
@media only screen and (max-width: 376px){
    
    .postThreeDotButton{
        margin-left: 8%;
        
    }
    .postThreeDotButton.btn.btn-primary{
        width: 15%;
    }
    .btn.btn-primary{
        width: 15%;
    }
    
    .deleteButton{
        margin-left: 15%;
    }
    /* .likeCount.btn.btn-primary{
        width: 30px;

    } */
    .likeCount.btn.btn-primary{
        width: 25%;
        font-size: 12px;

    }

    
    

    

}
@media only screen and (max-width: 321px){
    .PUN{
        font-size: 15px;
    }
    .btn.btn-primary{
        width: 20%;
    }
    .likeCount.btn.btn-primary{
        width: 25%;
        font-size: 10px;

    }
    .deleteButton{
        margin-left: 10%;
    }
    
    

    

}

