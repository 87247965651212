.form {
  background-color: #302e3fd2;
  border-radius: 5px;
  width: 550px;
  margin: 20px auto;
  padding: 20px;
  margin-top: 15vh;
}


.form-body {
  text-align: left;
  padding: 20px 10px;
}

.form-body > * {
  padding: 5px;
}

.form__label {
  width: 40%;
  height: auto;
}

.form_input {
  width: 100%;
  height: 40%;
  background-color: hsla(120, 40%, 98%, 0.541);
}

.footer {
  text-align: center;
}

h3{
  text-align: center;
}
