@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family= Roboto&display=swap");
@import url(https://allfont.net/allfont.css?fonts=star-jedi);

#simpleModal {
  font-family: "Roboto", sans-serif;
  background: url(../images/background.jpg) center center/cover;
  align-items: center;
}

#modalContent {
  position: absolute;
  height: 75vh;
  width: 45vw;
  top: 15vh;
  margin-left: 30vw;
  background: #2b2a2a;
  border-radius: 1vw;
}

#ModalBody {
  padding: 0vw;
}
.loginnav-item {
  padding: 0.5vw 2vw;
  background: transparent;
  display: list-item;
  font-size: 1.5vw;
  margin: 0 1.5vw;
  border-radius: 5px;
}

.loginnav-item:hover {
  background: #9747ff;
}

#LoginNavbar {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0vw;
  text-align: center;
  display: flex;
  justify-content: center;
}

#LRform svg {
  margin: 0.5vw;
}

#register_head {
  font-weight: 100;
  line-height: 41px;
  color: #ffffff;
  padding-left: 1vw;
  margin: 0 auto;
  font-size: 1rem;
}

input ::placeholder {
  font-size: small;
}

.textbox {
  align-items: center;
  background-color: white;
  padding-left: 0.5vw;
  display: flex;
  border-radius: 0.5vw;
  width: 23vw;
  height: 2.6vw;
}

.textbox ::placeholder {
  font-size: 20px;
}

#simpleModal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  left: 0;
  margin: 0;
  overflow: auto;
  top: 0;
  background-color: rgba(0, 0, 0, 0.611);
}

#LRform input {
  height: 2vw;
  padding: 0.5vw;
  color: black;
  width: 20vw;
  border: green;
  outline: none;
  font-size: 1vw;
  border-radius: 0.3vw;
}

.closebtn {
  float: right;
  font-size: 3vw;
  color: white;
  cursor: pointer !important;
}

.closebtn :hover {
  color: red;
}

#ModalHeader {
  margin-right: 1vw;
  padding: 0.6vw 0 0 0;
}

#LRform {
  display: flex;
  font-size: 2vw;
  flex-direction: column;
  height: 95%;
  align-items: center;
  justify-content: space-evenly;
}

#submitbtn {
  vertical-align: middle;
  font-size: 1.2vw;
  text-align: center;
  padding: 0.2vw 2vw 0.2vw 2vw;
  height: 2.6vw;
  width: 9vw;
  background-color: #ffffff;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 3px 5px 15px #201f1f;
}

#forgot {
  color: white;
  text-align: center;
  font-size: 1.4vw;
}

#regbtn {
  background-color: #9747ff;
  border-radius: 0.5vw;
  color: white;
  margin: 2vw 0vw;
  padding: 0.5vw 1.8vw;
  font-size: 1.5vw;
}

#submitbtn:hover {
  background-color: #9747ff;
  border-radius: 0.5vw;
  color: white;
}

#forgot Link {
  margin: 0;
  text-decoration: underline;
  background-color: transparent;
}

#forgot Link:hover {
  color: #9747ff;
}

@media screen and (max-width: 1000px) {
  #modalContent {
    margin-top: 10vh;
    margin-left: 8vh;
    position: relative;
    width: 70%;
    height: 70%;
    top: 0vw;
    right: 0vw;
    padding: 0vw;
  }

  .textbox ::placeholder {
    font-size: 20px;
  }
  #simpleModal .logo {
    margin: 5px;
    height: 20.5vw;
    margin-left: 2vw;
    width: 30vw;
    margin-top: 1.5vw;
  }

  #LRform svg {
    height: 4vw;
    width: 6vw;
  }
  #LRform input {
    height: 6vw;
    padding: 0.5vw;
    width: 80%;
    border-radius: 0.3vw;
  }
  .textbox {
    width: 80%;
    height: 7.6vw;
  }

  #login_heading,
  #register_head {
    font-size: 5vw;
  }
  #forgot {
    font-size: 4vw;
  }

  #submitbtn {
    height: 12%;
    width: 26%;
    font-size: 4vw;
  }
  #ModalHeader {
    margin-right: 1vw;
    padding: 2vw 0.6vw 0 0;
  }

  .closebtn {
    font-size: 9vw;
  }
}
@media screen and (max-width: 500px) {
  #modalContent {
    margin-top: 10vh;
    margin-left: 8vh;
    position: relative;
    width: 70%;
    height: 70%;
    top: 0vw;
    right: 0vw;
    padding: 0vw;
  }
  .textbox ::placeholder {
    font-size: 15px;
  }

  #simpleModal .logo {
    margin: 5px;
    height: 20.5vw;
    margin-left: 2vw;
    width: 30vw;
    margin-top: 1.5vw;
  }

  #LRform svg {
    height: 4vw;
    width: 6vw;
  }
  #LRform input {
    height: 6vw;
    padding: 0.5vw;
    width: 80%;
    border-radius: 0.3vw;
  }
  .textbox {
    width: 80%;
    height: 7.6vw;
  }

  #login_heading,
  #register_head {
    font-size: 5vw;
  }
  #forgot {
    font-size: 4vw;
  }

  #submitbtn {
    height: 12%;
    width: 26%;
    font-size: 4vw;
  }
  #ModalHeader {
    margin-right: 1vw;
    padding: 2vw 0.6vw 0 0;
  }

  .closebtn {
    font-size: 9vw;
  }
}
