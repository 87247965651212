*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  background: #fff;
  background-size:cover;
  background-position: center center;
background-attachment: fixed;
background-image: url(../images/background.jpg);

}
.about{
margin-left:160px;
font-size:40px;
font-weight:bold;
}

.container1 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 500px;
  height: 600px;
  font-family: 'Roboto', sans-serif;
  margin: 70px auto 0;
  margin-top:20px;
  border: 1px solid rgba(0, 0, 0, 0.274);
}
@media screen and (max-width:720px){
  .container1{
    height:800px;

  }
}
.footer{
  margin-left: 0%;
}

.bloc-tabs {
  display: flex;
}
.tabs {

  padding: 15px;
  text-align: center;
  width: 50%;
  background: white;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
  font-family: 'Roboto', sans-serif;
  
}
.tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs  {
  background: rgb(85, 87, 119);
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

.icon{
  margin-left: 6vw;
  margin-right:3vw;
  margin-bottom: 5vh;
}

button {
  border: none;
  
}
.content-tabs {
  flex-grow : 1;
}
.content {
  /* background-image:url('https://www.hdwallpapers.in/download/stars_space_darkness_night_black_sky_background_hd_space-2560x1440.jpg'); */
  opacity: 0.9;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
  font-family: 'Roboto', sans-serif;
}
.content2 {
 /* background-color: rgb(143, 133, 178); */
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
  opacity:0.9;
  font-family: 'Roboto', sans-serif;
  
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  margin-top:40px;
  text-align: justify;
  height: 100%;
  color:white;
}
.active-content {
  display: block;
}
img{
  margin-top:5px;
  margin-right:5px;
  
  border-radius:5px;
}
.content2 p {
  width: 100%;
  margin-top:40px;
  text-align: justify;
  height: 100%;
  color:white;
}
.flip-card {
  background-color: transparent;
  width: 140px;
  height: 140px;
  font-family: 'Roboto', sans-serif;
  margin-left:20px;
  margin-bottom:20px;
  display:inline-block;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  border-radius:20px;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: rgb(104, 113, 162);
  color: white;
  transform: rotateY(180deg);
}
.muskan{
  margin-top:0px;
  height:140px;
  width:140px;
  
  border-radius:20px;
}
.muskand{
  text-align:center;
 font-size:12px;
  padding-bottom:5px;
  padding-left:4px;
  padding-right:2px;
}
.anamika{
  margin-left: 0px;
  margin-top:0px;
  height:140px;
  width:140px;
  border-radius:20px;
}
.anamikad{
  text-align:center;
 font-size:12px;
  padding-bottom:5px;
  padding-left:4px;
  padding-right:2px;
}
.flip-carda {
display: inline-block;
  background-color: transparent;
  width: 140px;
  height: 140px;
  margin-left:20px;
  margin-bottom:20px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.flip-carda-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-carda:hover .flip-carda-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-carda-front, .flip-carda-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius:20px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-carda-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-carda-back {
  background-color: rgb(104, 113, 162);
  color: white;
  transform: rotateY(180deg);
}

.ridhi{
  
 
  margin-top:0px;
  height:140px;
  width:130px;
  border-radius:20px;
}
.ridhid{
  text-align:center;
 font-size:12px;
  padding-bottom:5px;
  padding-left:4px;
  padding-right:2px;
}
.flip-cardr {
  display:inline-block;
  background-color: transparent;
  width: 130px;
  height: 140px;
  margin-bottom:20px;
  
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.flip-cardr-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-cardr:hover .flip-cardr-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-cardr-front, .flip-cardr-back {
  position: absolute;
  width: 100%;
  border-radius:20px;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-cardr-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-cardr-back {
  background-color: rgb(104, 113, 162);
  color: white;
  transform: rotateY(180deg);
}
.alka{
  margin-top:0px;
  height:150px;
  border-radius:20px;
  width:140px;
  
}
.alkad{
  text-align:center;
 font-size:12px;
  padding-bottom:5px;
  padding-left:4px;
  border-radius:20px;
  padding-right:2px;
}
.flip-cardl {
  display:inline-block;
  align-items:flex-start;
  background-color: transparent;
  width: 138px;
  border-radius:20px;
  height: 150px;
  border-radius:20px;
  margin-left:45px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.flip-cardl-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-cardl:hover .flip-cardl-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-cardl-front, .flip-cardl-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius:20px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-cardl-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-cardl-back {
  background-color: rgb(104, 113, 162);
  color: white;
  transform: rotateY(180deg);
}

.upasana{
  margin-top:0px;
  height:150px;
  width:140px;
  border-radius:20px;
}
.upasanad{
  text-align:center;
 font-size:12px;
  padding-bottom:5px;
  padding-left:4px;
  padding-right:2px;
}
.flip-cardu {
  display:inline-block;
 
  background-color: transparent;
  width: 140px;
  height: 150px;
  border-radius:20px;
  margin-left:85px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.flip-cardu-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius:20px;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-cardu:hover .flip-cardu-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-cardu-front, .flip-cardu-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius:20px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-cardu-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-cardu-back {
  background-color: rgb(104, 113, 162);
  color: white;
  transform: rotateY(180deg);
}
.App-logo{
  width: 40vmin;
  border-color: #fff;
}
.flip-cardh {
  display:inline-block;
 
  background-color: transparent;
  width: 140px;
  border-radius:20px;
  height: 150px;
  border-radius:20px;
  margin-left:155px;
  margin-top:5px;
  
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}
.flip-cardh-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-cardh:hover .flip-cardh-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-cardh-front, .flip-cardh-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius:20px;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-cardh-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-cardh-back {
  background-color: rgb(104, 113, 162);
  color: white;
  transform: rotateY(180deg);
}
.harshita{
  margin-top:0px;
  height:150px;
  width:140px;
  border-radius:20px;
}
.harshitad{
  text-align:center;
 font-size:12px;
  padding-bottom:5px;
  padding-left:4px;
  padding-right:2px;
}
/* 
.social-container {
  
  padding: 25px 50px;
}
a.social {
  margin: 0 1rem;
  margin-left:50px;
  transition: transform 250ms;
  display: inline-block;
}
.connect{
  margin-left:92px;
}
a.social:hover {
  transform: translateY(-6px);
  size:4x;
  box-shadow: 0 5px 15px rgba(145, 92, 182, .4);
  border-radius:10px;
  height:40px;
  

}
a.twitter {
  color: white;
}
.icons{
  height:40px;
  
}
a.linkedin{
  color:white;
}
a.instagram {
  color: rgb(244, 238, 238);
} */
@media screen and (max-width:720px){
  .container1{
    width:300px;
    height:900px;
    margin-left:15px;
    margin-right:0px;
    margin-top:10px;
  }
  .tabs{
    font-size:12px;
    padding:5px;

  }
  .cbd{
    font-size:12px;
    margin-top:5px;
  }
  .cbpic{
    height:100px;
    width:250px;
    margin-top:5px;
  }
  .icons{
    size:2x;
  }
  .connect{
    margin-left:20px;
  }
  .hallpic{
    height:150px;
    opacity:0.7;
  }
  .ridhi{
    width:120px;
    height:120px;
  }
  .ridhid{
    font-size:10px;
    margin-top:0px;
  }
  .all{
    font-size:15px;
  }
  .flip-cardr{
    height:120px;
    width:120px;
    margin-left:70px;
  }
  .muskan{
    width:120px;
    height:120px;
  }
  .muskand{
    font-size:9px;
    margin-top:0px;
  }
  .flip-card{
    width:120px;
    height:120px;
    margin-left:70px;
  }
  .anamika{
    width:120px;
    height:120px;
  }
  .anamikad{
    font-size:9px;
    margin-top:0px;
  }
  .flip-carda{
    width:120px;
    height:120px;
    margin-left:70px;
  }
  .alka{
    width:120px;
    height:120px;
  }
  .alkad{
    font-size:9px;
    margin-top:0px;
  }
  .flip-cardl{
    
    width:120px;
    height:120px;
    margin-left:70px;
  }
  .upasana{
    width:120px;
    height:130px;
  }
  .upasanad{
    font-size:9px;
    margin-top:0px;
  }
  .flip-cardu{
    width:120px;
    margin-top:12px;
    height:120px;
    margin-left:70px;
  }
  .harshita{
    width:120px;
    height:130px;
  }
  .harshitad{
    font-size:9px;
    margin-top:0px;
  }
  .flip-cardh{
    width:120px;
    margin-top:12px;
    height:120px;
    margin-left:70px;
  }

}
